import React from "react";
import SVG from "react-inlinesvg";
import arrowSVG from "../../assets/images/arrow.svg";
import cup from "../../assets/images/icons/cup.svg";
import icon from "../../assets/images/icons/icon.svg";
import brick from "../../assets/images/icons/box_brick.svg";
import styles from './Advantages.module.scss';
import {useTranslation} from "react-i18next";

const Advantages = () => {
    const {t} = useTranslation();

    const advantagesList = [
        {img: cup, text: t('15_years'), description: t('experience_in _cargo_transportation')},
        {img: icon, text: t('3_years'), description: t('experience_in_the_Polish_market')},
        {img: brick, text: t('1500'), description: t('completed_orders')},
    ];

    const benefitsList = [
        t('private_price'),
        t('связь_24_7'),
        t('experienced_drivers'),
        t('short_terms'),
        t('guarantee_of_cargo_safety'),
        t('punctuality'),
        t('assistance_with_loading_and_unloading'),
        t('working_with_legal_entities_and_individuals'),
        t('reliability'),
    ];

    return (
        <section className={styles.advantages}>
            <div className={styles.advantages__desc}>
                {advantagesList.map(({img, text, description}, index) => (
                    <div className={styles.box} key={index}>
                        <div>
                            <img src={img} alt={`advantage-${index}`}/>
                            <p>{text}</p>
                        </div>
                        <span>{description}</span>
                    </div>
                ))}
            </div>

            <div className={styles.advantages__benefits}>
                {Array.from({length: Math.ceil(benefitsList.length / 3)}, (_, rowIndex) => (
                    <div key={rowIndex} className={styles["advantages__benefits-column"]}>
                        {benefitsList.slice(rowIndex * 3, rowIndex * 3 + 3).map((text, benefitIndex) => (
                            <p key={benefitIndex}>
                                <SVG src={arrowSVG}/>
                                {text}
                            </p>
                        ))}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Advantages