import React from "react";
import closeImage from "../../assets/images/close.png"
import styles from "./OrderOnlineModal.module.scss";
import calendarBlackIcon from "../../assets/images/v2SocialLinks/calendar.svg";
import phoneIcon from "../../assets/images/v2SocialLinks/phone.svg";
import telegramIcon from "../../assets/images/v2SocialLinks/telegram.svg";
import {useTranslation} from 'react-i18next';

const OrderOnlineModal = ({title, description, isOpen, onClose}) => {
    const {t} = useTranslation()
    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay} onClick={onClose}>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                <button className={styles.modalClose} onClick={onClose}>
                    <img src={closeImage} alt=""/>
                </button>
                <div className="modal-body">
                    <div className={styles.orderOnlineModal}>
                        <p
                            className={`${styles.header} secondary`}
                            dangerouslySetInnerHTML={{__html: title}}
                        />
                        <span
                            className={`${styles.info} secondary`}
                            dangerouslySetInnerHTML={{__html: description}}
                        />
                    </div>
                    <div className={styles.modalSocialLinksSection}>
                        <button className={styles.boxImage}
                             onClick={() => window.open("https://calendly.com/freedom_trans", "_blank")}>
                            <img src={calendarBlackIcon} alt=""/>
                            <span>{t('order_online')}</span>
                        </button>
                        <button className={styles.boxImage} onClick={() => window.open("tel:+48573666398", "_blank")}>
                            <img src={phoneIcon} alt="Polski"/>
                            <span>+48 573 666 398</span>
                        </button>
                        <button className={styles.boxImage} onClick={() => window.open("https://t.me/obutsko", "_blank")}>
                            <img src={telegramIcon} alt=""/>
                            <span>Telegram</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderOnlineModal;
