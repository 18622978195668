import React, {useState} from "react";
import SVG from "react-inlinesvg";
import smallLogoSVG from "../../assets/images/smalllogo.svg";
import styles from "./Footer.module.scss";
import phoneIcon from "../../assets/images/v2SocialLinks/phone.svg";
import telegramIcon from "../../assets/images/v2SocialLinks/telegram.svg";
import onlineIcon from "../../assets/images/v2SocialLinks/online.svg";
import {useTranslation} from "react-i18next";

const Footer = () => {
  const {t} = useTranslation()

  return (
    <footer className={styles.footer__container}>
      <div className={styles.footer}>
        <div className={styles.footer_links}>
          <SVG className={styles.logo} src={smallLogoSVG}/>
          <div className={`${styles.socialLinksSection}`}>
            <button onClick={() => window.open("tel:+48573666398", "_blank")}>
              <img src={phoneIcon} alt=""/>
              <span>
                +48 573 666 398
              </span>
            </button>
            <button onClick={() => window.open("https://t.me/obutsko", "_blank")}>
              <img src={telegramIcon} alt=""/>
              <span>Telegram</span>
            </button>
            <div className={styles.primaryButton}>
              <button className={styles.showWebDevice}
                      onClick={() => window.open("https://calendly.com/freedom_trans", "_blank")}>
                <img src={onlineIcon} alt=""/>
                <span>{t('order_online')}</span>
              </button>
            </div>
          </div>
          <div className={`${styles.showMobileDevice}`}>
            <button onClick={() => window.open("https://calendly.com/freedom_trans", "_blank")}>
              <img src={onlineIcon} alt=""/>
              <span>{t('order_online')}</span>
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
