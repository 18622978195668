import React, { useState } from "react";
import styles from "./DeliveryTypes.module.scss";
import OrderOnlineModal from "../Modal/OrderOnlineModal";

const DeliveryTypesType = (props) => {
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const fromType = true;

    return (
        <>
            <div className={props.className} onClick={openModal}>
                <div className={styles["img-wrapper"]}>
                    <img src={props.imageSrc} alt={props.title}/>
                </div>
                <p dangerouslySetInnerHTML={{__html: props.title}}/>
            </div>
            <OrderOnlineModal title={props.title} description={props.description} fromType={fromType} isOpen={isModalOpen} onClose={closeModal}/>
        </>
    );
};

export default DeliveryTypesType;
