import React from "react";

import DeliveryTypes from "./components/DeliveryTypes";
import Header from "./components/Header";
import Advantages from "./components/Advantages";
import Locations from "./components/Locations";
import Footer from "./components/Footer";
import Garage from "./components/Garage";
import CallCentre from "./components/AboutUs/AboutUs";

import "normalize.css";
import "./App.scss";
import Comments from "./components/Comments/Comments";

function App() {
  return (
    <div className="main">
      <Header />
      <CallCentre />
      <DeliveryTypes />
      <Advantages />
      <Locations />
      <Garage />
      <Comments/>
      <Footer />
    </div>
  );
}

export default App;
