import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LanguageSwitcher.module.scss';
import plIcon from '../../../assets/images/flug/pl.svg';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    // Determine the current language and the button text
    const currentLanguage = i18n.language;
    const toggleLanguage = currentLanguage === 'ru' ? 'pl' : 'ru';
    const toggleButtonText = currentLanguage === 'ru' ? (isMobile ? 'PL' : 'Polski') : 'На русском';
    const showIcon = currentLanguage === 'ru';

    return (
        <div className={styles.languageSwitcher}>
            <button onClick={() => changeLanguage(toggleLanguage)}
                    aria-label={`Switch to ${toggleButtonText}`}
            >
                {showIcon ? <img src={plIcon} alt="Polski" className={styles.languageIcon}/> : null}
                {toggleButtonText}
            </button>
        </div>
    );
};

export default LanguageSwitcher;
