import React, { useMemo } from "react";
import { useTranslation } from 'react-i18next';
import couchAndLampPNG from "../../assets/images/icons/couch.png";
import desktopComputerPNG from "../../assets/images/icons/desktop.png";
import packagePNG from "../../assets/images/icons/package.png";
import brickPNG from "../../assets/images/icons/brick.png";
import dogPNG from "../../assets/images/icons/dog.png";
import platePNG from "../../assets/images/icons/plate.png";
import drumPNG from "../../assets/images/icons/drum.png";
import busPNG from '../../assets/images/icons/bus.png';
import DeliveryTypesType from "./_Type";
import styles from "./DeliveryTypes.module.scss";

const DeliveryTypes = (props) => {
    const {t} = useTranslation();
    const typesItems = useMemo(
        () => [
            {
                imageSrc: couchAndLampPNG,
                title: "apartment_moving",
                description: "apartment_moving_description",
            },
            {
                imageSrc: desktopComputerPNG,
                title: "office_and_business_relocations",
                description: "office_and_business_relocations_description",
                mobileHTMLTitle: "office_and_business_relocations",
            },
            {
                imageSrc: packagePNG,
                title: "delivery_of_goods_from_stores",
                description: "delivery_of_goods_from_stores_description",
                mobileHTMLTitle: "delivery_of_goods_from_stores",
            },
            {
                imageSrc: brickPNG,
                title: "delivery_of_building_materials",
                description: "delivery_of_building_materials_description",
                mobileHTMLTitle: "delivery_of_building_materials",
            },
            {
                imageSrc: dogPNG,
                title: "transportation_of_animals",
                description: "transportation_of_animals_description",
            },
            {
                imageSrc: platePNG,
                title: "transportation_of_fragile_goods",
                description: "transportation_of_fragile_goods_description",
                mobileHTMLTitle: "transportation_of_fragile_goods",
            },
            {
                imageSrc: drumPNG,
                title: "transportation_of_non_standard_cargo",
                description: "transportation_of_non_standard_cargo_description",
                mobileHTMLTitle: "transportation_of_non_standard_cargo",
            },
            {
                imageSrc: busPNG,
                title: "passenger_transportation",
                description: "passenger_transportation_description",
            },
        ],
        []
    );

    return (
        <section className={styles.types}>
            <div className={styles["types__items-block"]}>
                {typesItems.map((item, i) => (
                    <DeliveryTypesType
                        title={t(item.title)}
                        description={t(item.description)}
                        imageSrc={item.imageSrc}
                        className={styles.types__items}
                        key={i}
                    />
                ))}
            </div>
        </section>
    );
};

export default DeliveryTypes;
