'use client'

import React, { useEffect, useRef } from "react";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import styles from './Locations.module.scss';
import { useTranslation } from "react-i18next";

mapboxgl.accessToken = 'pk.eyJ1IjoiZ2lyaWNoZXYiLCJhIjoiY20xaG9iYW16MGVjcDJwczUzbnc4Ym9ldCJ9.WSfYu3pz_RrjknGTAUHzpg'; // Replace with your Mapbox access token

const Locations = () => {
    const { t } = useTranslation();
    const mapContainer = useRef(null);
    
    useEffect(() => {
        const zoomLevel = window.innerWidth <= 768 ? 2.9 : 4.5; // Change to desired level for larger screens

        const map = new mapboxgl.Map({
            container: mapContainer.current, // Ref to container
            style: 'mapbox://styles/mapbox/satellite-streets-v12', // Satellite with streets style
            center: [21.0122, 52.2297], // Centered
            zoom: zoomLevel, // Zoom level
            scrollZoom: false, // Disable zooming with scroll
            dragPan: false, // Disable dragging/panning
            dragRotate: false, // Disable rotation
            touchZoomRotate: false, // Disable touch gestures for zoom/rotate
            doubleClickZoom: false, // Disable zooming with double-click
        });

        // map.on('moveend', () => {
        //     const center = map.getCenter();
        //     console.log(center);
        // });

        const warsaw = { name: 'Warsaw', coords: [21.0122, 52.2297] };
        const trips = [
            { name: 'Terespol', coords: [23.6152, 52.0765] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Utrecht', coords: [5.1214, 52.0907] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Terespol', coords: [23.6152, 52.0765] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Mülheim', coords: [6.8831, 51.4308] },
            { name: 'Plotsk', coords: [19.7062, 52.5468] },
            { name: 'Poznan', coords: [16.9252, 52.4064] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Radom', coords: [21.1470, 51.4027] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Przemysl', coords: [22.7679, 49.7838] },
            { name: 'Tuscany', coords: [11.2558, 43.7711] },
            { name: 'Piastow', coords: [20.8406, 52.1798] },
            { name: 'Kalisz', coords: [18.0846, 51.7592] },
            { name: 'Gdansk', coords: [18.6466, 54.3520] },
            { name: 'Poznan', coords: [16.9252, 52.4064] },
            { name: 'Katowice', coords: [19.0238, 50.2649] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Kolobrezh', coords: [15.5748, 54.1769] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Amsterdam', coords: [4.9041, 52.3676] },
            { name: 'Radom', coords: [21.1470, 51.4027] },
            { name: 'Bialystok', coords: [23.1688, 53.1325] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Katowice', coords: [19.0238, 50.2649] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Brno', coords: [16.6068, 49.1951] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Konstantia', coords: [28.6314, 44.1768] },
            { name: 'Sokhachev', coords: [20.2388, 52.2307] },
            { name: 'Grabov', coords: [19.3646, 52.0342] },
            { name: 'Plock', coords: [19.7030, 52.5468] },
            { name: 'Vilnius', coords: [25.2797, 54.6872] },
            { name: 'Kasandra', coords: [23.3855, 39.9609] },
            { name: 'Vilnius', coords: [25.2797, 54.6872] },
            { name: 'Plonsk', coords: [20.3735, 52.6226] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Modlin', coords: [20.6820, 52.4359] },
            { name: 'Terespol', coords: [23.6152, 52.0765] },
            { name: 'Radon', coords: [21.1470, 51.4027] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Bialystok', coords: [23.1688, 53.1325] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Kielce', coords: [20.6286, 50.8661] },
            { name: 'Basel', coords: [7.5886, 47.5596] },
            { name: 'Bialystok', coords: [23.1688, 53.1325] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Gdynia', coords: [18.5306, 54.5189] },
            { name: 'Munich', coords: [11.5820, 48.1351] },
            { name: 'Munich', coords: [11.5820, 48.1351] },
            { name: 'Kralovice', coords: [13.4898, 49.9820] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Bygdosch', coords: [18.0120, 53.1235] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Olsztyn', coords: [20.4801, 53.7784] },
            { name: 'Gdynia', coords: [18.5306, 54.5189] },
            { name: 'Poznan', coords: [16.9252, 52.4064] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Gdansk', coords: [18.6466, 54.3520] },
            { name: 'Kielce', coords: [20.6286, 50.8661] },
            { name: 'Barcelona', coords: [2.1734, 41.3851] },
            { name: 'Radom', coords: [21.1470, 51.4027] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Vilnius', coords: [25.2797, 54.6872] },
            { name: 'Poznan', coords: [16.9252, 52.4064] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Poznan', coords: [16.9252, 52.4064] },
            { name: 'Bialystok', coords: [23.1688, 53.1325] },
            { name: 'Olsztyn', coords: [20.4801, 53.7784] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Vilnius', coords: [25.2797, 54.6872] },
            { name: 'Plonsk', coords: [20.3735, 52.6226] },
            { name: 'Zakopane', coords: [19.9495, 49.2992] },
            { name: 'Vienna', coords: [16.3738, 48.2082] },
            { name: 'Budapest', coords: [19.0402, 47.4979] },
            { name: 'Sandomierz', coords: [21.7514, 50.6821] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Vilnius', coords: [25.2797, 54.6872] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Biala Podlaska', coords: [23.1321, 52.0324] },
            { name: 'Biala Podlaska', coords: [23.1321, 52.0324] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Poznan', coords: [16.9252, 52.4064] },
            { name: 'Lodz', coords: [19.4560, 51.7592] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Pulawy', coords: [21.9690, 51.4162] },
            { name: 'Bialystok', coords: [23.1688, 53.1325] },
            { name: 'Wroclaw', coords: [17.0385, 51.1079] },
            { name: 'Krakow', coords: [19.9450, 50.0647] },
            { name: 'Lochow', coords: [21.6894, 52.5301] }    
        ];

        function getBezierCurve(start, end, curveAmount = 0.2) {
            const midX = (start[0] + end[0]) / 2;
            const midY = (start[1] + end[1]) / 2;
            const deltaX = end[0] - start[0];
            const deltaY = end[1] - start[1];
            
            const controlX = midX - deltaY * curveAmount;
            const controlY = midY + deltaX * curveAmount;
            
            const curvePoints = [];
            for (let t = 0; t <= 1; t += 0.01) {
                const x = Math.pow(1-t, 2) * start[0] + 
                          2 * (1-t) * t * controlX + 
                          Math.pow(t, 2) * end[0];
                const y = Math.pow(1-t, 2) * start[1] + 
                          2 * (1-t) * t * controlY + 
                          Math.pow(t, 2) * end[1];
                curvePoints.push([x, y]);
            }
            return curvePoints;
        }
    
        map.on('load', () => {
            const isMobile = window.innerWidth <= 768;
            const mapCenter = isMobile ? [2.5, 50.0] : [8.5, 50.0];
            map.setCenter(mapCenter);

            // Create routes from Warsaw to each destination
            trips.forEach((trip, index) => {
                const routeCoordinates = getBezierCurve(warsaw.coords, trip.coords);
                
                map.addSource(`route-${index}`, {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'properties': {},
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': routeCoordinates
                        }
                    }
                });
    
                map.addLayer({
                    'id': `route-${index}`,
                    'type': 'line',
                    'source': `route-${index}`,
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': '#fff',
                        'line-width': 1.5,
                        'line-opacity': 0.5
                    }
                });
            });
    
            // Add marker for Warsaw
            const warsawEl = document.createElement('div');
            warsawEl.className = styles['marker'];

            new mapboxgl.Marker(warsawEl)
                .setLngLat(warsaw.coords)
                .addTo(map);
    
            const getRandomDelay = () => {
                return Math.random() * 2; // Random delay between 0 and 2 seconds
            };
            
            const uniqueDestinations = [...new Set(trips.map(trip => trip.name))];
            uniqueDestinations.forEach((cityName) => {
                const city = trips.find(trip => trip.name === cityName);
                
                const cityEl = document.createElement('div');
                cityEl.className = styles['pulse-marker'];
            
                // Apply random animation delay
                cityEl.style.setProperty('--animation-delay', `${getRandomDelay()}s`);
                
                new mapboxgl.Marker(cityEl)
                    .setLngLat(city.coords)
                    .addTo(map);
            });
        });

        return () => map.remove(); // Cleanup map on component unmount
    }, []);

    return (
        <section className={styles.locations}>
            <p dangerouslySetInnerHTML={{ __html: t('delivery_EC') }} />
            <div ref={mapContainer} className={styles.mapContainer} >
                {/* Overlay to dim the map */}
                <div className={styles['map-dim-overlay']} />
            </div>
        </section>
    );
}


export default Locations