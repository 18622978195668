import {useTranslation} from "react-i18next";
import styles from "./Comments.module.scss";
import starImage from "../../assets/images/icons/star.svg";
import React from "react";

const Comments = () => {

    const {t} = useTranslation();

    return (
        <section>
            <div className={styles.header}>
                <p>{t('our_value_and_comments')}</p>
            </div>
            <div className={styles.boxContainer}>
                <div className={styles.boxComments}>
                    <div>
                        <div>
                            <div className={styles.boxStars}>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                            </div>
                            <p className={`secondary`}
                               dangerouslySetInnerHTML={{__html: t('comments_1.comments')}}/>
                        </div>
                        <div className={styles.boxAuthor}>
                            <span className={styles.author}>{t('comments_1.author')}</span>
                            <span>{t('comments_1.country')}</span>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className={styles.boxStars}>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                            </div>
                            <p className={`secondary`}
                               dangerouslySetInnerHTML={{__html: t('comments_2.comments')}}/>
                        </div>
                        <div className={styles.boxAuthor}>
                            <span className={styles.author}>{t('comments_2.author')}</span>
                            <span>{t('comments_2.country')}</span>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className={styles.boxStars}>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                            </div>
                            <p className={`secondary`}
                               dangerouslySetInnerHTML={{__html: t('comments_3.comments')}} size={'xl'}/>
                        </div>
                        <div className={styles.boxAuthor}>
                            <span className={styles.author}>{t('comments_3.author')}</span>
                            <span>{t('comments_3.country')}</span>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className={styles.boxStars}>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                            </div>
                            <p className={`${styles.title} secondary`}
                               dangerouslySetInnerHTML={{__html: t('comments_4.comments')}} size={'xl'}/>
                        </div>
                        <div className={styles.boxAuthor}>
                            <span className={styles.author}>{t('comments_4.author')}</span>
                            <span>{t('comments_4.country')}</span>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className={styles.boxStars}>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                            </div>
                            <p className={`secondary`}
                               dangerouslySetInnerHTML={{__html: t('comments_5.comments')}} size={'xl'}/>
                        </div>
                        <div className={styles.boxAuthor}>
                            <span className={styles.author}>{t('comments_5.author')}</span>
                            <span>{t('comments_5.country')}</span>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className={styles.boxStars}>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                            </div>
                            <p className={`secondary`}
                               dangerouslySetInnerHTML={{__html: t('comments_6.comments')}} size={'xl'}/>
                        </div>
                        <div className={styles.boxAuthor}>
                            <span className={styles.author}>{t('comments_6.author')}</span>
                            <span>{t('comments_6.country')}</span>
                        </div>
                    </div>

                    <div>
                        <div>
                            <div className={styles.boxStars}>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                                <img src={starImage} alt=""/>
                            </div>
                            <p className={`secondary`}
                               dangerouslySetInnerHTML={{__html: t('comments_7.comments')}} size={'xl'}/>
                        </div>
                        <div className={styles.boxAuthor}>
                            <span className={styles.author}>{t('comments_7.author')}</span>
                            <span>{t('comments_7.country')}</span>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Comments