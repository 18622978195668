import React from "react";
import logoSVG from "../../assets/images/logo.svg";

import styles from "./Header.module.scss";
import LanguageSwitchers from './LanguageSwitcher/LanguageSwitcher';

const Header = () => {
  return (
    <header className={styles.header}>
        <div className={styles.headerContent}>
            <img src={logoSVG} alt="" className={styles.logo}/>
            <LanguageSwitchers/>
        </div>
    </header>
  );
};

export default Header;