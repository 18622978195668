import React from "react";
import {useTranslation} from "react-i18next";

import xIcon from "../../assets/images/garage/x.svg";
import kettlebellIcon from "../../assets/images/garage/kettlebell.svg";
import fordIcon from "../../assets/images/garage/ford.png";

import styles from "./Garage.module.scss";

const Garage = () => {
    const {t} = useTranslation();

    return (
        <section className={styles.garage}>
            <div className={styles.boxDesc}>
                <p className={styles.desc}>
                    {t('Dimensions_for_cargo_transportation')}
                </p>
            </div>
            <div className={styles.imgWrapper}>
                <img src={fordIcon} alt={t('Ford vehicle')}/>
            </div>
            <div className={styles.optionsSection}>
                <div className={styles.boxOption}>
                    <div>
                        <span>{t('length')}</span>
                        <p>3,300 мм</p>
                    </div>
                    <div className={styles.xIcon}>
                        <img src={xIcon} alt={t('✕')}/>
                    </div>
                    <div>
                        <span>{t('width')}</span>
                        <p>2,000 мм</p>
                    </div>
                    <div className={styles.xIcon}>
                        <img src={xIcon} alt={t('✕')}/>
                    </div>
                    <div>
                        <span>{t('Height')}</span>
                        <p>1,800 мм</p>
                    </div>
                    <div className={styles.showMobileDevice}>
                        <span>{t('load_capacity')}</span>
                        <p>1,000 кг</p>
                    </div>
                </div>
                <div className={styles.showWebDevice}>
                    <div>
                        <span>{t('load_capacity')}</span>
                        <p>1,000 кг</p>
                    </div>

                    <img src={kettlebellIcon} alt={t('Weight')}/>
                </div>
            </div>
        </section>
    );
};

export default Garage;
