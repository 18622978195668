import { useTranslation } from 'react-i18next';
import styles from "./AboutUs.module.scss";
import telegramIcon from "../../assets/images/v2SocialLinks/telegram.svg";
import phoneIcon from "../../assets/images/v2SocialLinks/phone.svg";
import orderIcon from "../../assets/images/v2SocialLinks/order.svg";
import React from "react";

const AboutUs = () =>{
    const { t } = useTranslation();
    return (
        <div className={styles.sectionAboutUs}>
            <div className={styles.header}>
                <p className={`${styles.title} secondary`} dangerouslySetInnerHTML={{__html: t('call_centre_info')}} size={'xl'}/>
            </div>
            <div className={`${styles.phoneSection}`}>
                <div className={styles.phoneNumber}>
                    <p>+48 573 666 398</p>
                </div>
                <div className={styles.groupCallCentre}>
                    <div className={styles.socialLinksSection}>
                        <div className={styles.groupCallBtn}>
                            <button onClick={() => window.open("tel:+48573666398", "_blank")}>
                                <img src={phoneIcon} alt="Polski"/>
                                <span>{t('call')}</span>
                            </button>
                            <button onClick={() => window.open("https://t.me/obutsko", "_blank")}>
                                <img src={telegramIcon} alt="Polski"/>
                                <span>Telegram</span>
                            </button>
                        </div>
                    </div>
                    <div className={styles.orderBtnSection}>
                        <button onClick={() => window.open("https://calendly.com/freedom_trans", "_blank")}>
                            <img src={orderIcon} alt=""/>
                            <span>
                                {t('order_online')}
                        </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutUs